<template>
  <div>
    <div class="container sm:px-10" style="padding: 0">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen" style="padding: 40px">
          <a class="-intro-x flex items-center pt-5">
            <img
              alt=""
              class="w-8"
              :src="require(`@/assets/images/logo.svg`)"
              @click="getHome"
            />
            <span
              class="text-white text-lg ml-3"
              style="font-size: 1.4vw"
              @click="getHome"
              >智莺</span
            >
          </a>
          <div class="my-auto" style="margin-right: 200px">
            <img
              style="margin: 0 auto 20px"
              class="-intro-x w-1/2 -mt-16 loginImg"
              :src="require(`@/assets/images/sapiens.svg`)"
            />
            <div
              class="-intro-x text-white font-medium text-3xl leading-tight"
              style="font-size: 1.4vw; margin-top: 0.9vw"
            >
              智莺全渠道APP、小程序、H5电商解决方案,
              助力淘客，商家，门店，社区团购，直播进入数字营销时代
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 px-5 xl:py-0 xl:my-0">
          <div
            class="mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
            style="margin:auto auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
              style="font-size: 1.3rem"
            >
              登录
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              <!-- A few more clicks to sign in to your account. Manage all your
              e-commerce accounts in one place -->
            </div>
            <div class="intro-x mt-8">
              <input
                v-model="account.phone"
                type="text"
                class="intro-x login__input input input--lg border border-gray-300 block"
                placeholder="手机号码"
              />
              <input
                v-model="account.psw"
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="密码"
                style="margin: 40px 0"
              />
            </div>
            <div class="intro-x flex text-gray-700 text-xs sm:text-sm mt-4">
              <div class="flex items-center mr-auto">
                <input
                  id="remember-me"
                  v-model="account.Label"
                  type="checkbox"
                  class="input border mr-2"
                />
                <label class="cursor-pointer select-none" for="remember-me"
                  >记住密码</label
                >
              </div>
              <router-link to="/isRegister">忘记密码</router-link>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="button button--lg w-full xl:w-32 text-white bg-theme-1 xl:mr-3 align-top"
                style="border: 1px solid #1890ff"
                @click="getLogin"
              >
                登录
              </button>
              <button
                class="button button--lg w-full xl:w-32 text-gray-700 border border-gray-300 mt-3 xl:mt-0 align-top"
                @click="getSignUp"
              >
                注册
              </button>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import request from "../utils/request";
 

export default {
  data() {
    return {};
  },
  computed: {
    account() {
      if (Cookies.get("account")) {
        let data = JSON.parse(Cookies.get("account"));
        return {
          phone: data.phone,
          psw: data.psw,
          Label: data.Label,
        };
      } else {
        return {
          phone: "",
          psw: "",
          Label: false,
        };
      }
    },
  },
  mounted() {
    document.body.style.padding = 0;
    cash("body").removeClass("app").addClass("login");
    // setTimeout(()=>{
    //   import("../components/global");
    //   import("../utils");
    //   import("../assets/sass/app2.scss")
    // },3000)
  },
  methods: {
    // 跳转至首页
    getHome() {
      this.$router.push("/");
    },
    // 登录
    getLogin: function () {
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.account.phone)) {
        //正则校验手机号是否合法
        return this.$message.error("手机号错误");
      }
      if (this.account.psw === "") {
        return this.$message.error("请输入密码");
      }

      request({
        url: "/api/sign/in",
        method: "POST",
        data: {
          phone: this.account.phone,
          psw: this.account.psw,
        },
      }).then((result) => {

        if (result.code === 0) {
          this.$router.push({
            name: "side-menu-dashboard",
            params: {
              isPop: true,
            },
          });
          this.$message.success("登录成功");
          if (this.account.Label) {
            Cookies.set("account", this.account, { expires: 7 }); // 记住账号密码7天后过期
          } else {
            Cookies.remove("account");
          }
        }
      });
    },

    getSignUp: function () {
      this.$router.push("/register");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
 


